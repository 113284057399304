import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";

import { l } from "@emerson/locales/i18n";
import {
  SvgEmersonLogo,
  SvgFullscriptLogoDarkTextGreenLeaf,
  SvgFullscriptLogo,
} from "@shared/assets/logo";
import { Header } from "@shared/components/Header/Header";
import { sharedRoutes } from "@shared/data/sharedRoutes";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { Link } from "@shared/react-router-dom";

import * as styles from "./AuthHeader.styles";
interface Props {
  route?: string;
  showFromEmerson?: boolean;
}

const AuthHeader = ({ route, showFromEmerson = false }: Props) => {
  const { t } = useTranslation();
  const { isEmerson } = useSharedGlobalConfig();
  const currentTheme = useTheme();

  const renderEmersonEcologicsLogo = () => (
    <Link to={sharedRoutes.emerson.base}>
      <SvgEmersonLogo
        role="img"
        data-testid="emerson-logo"
        data-e2e="emerson-logo"
        aria-label={t(l.companies.EmersonEcologics)}
        css={styles.emersonLogo}
      />
    </Link>
  );

  const renderPlatformLogo = () => {
    if (isEmerson || showFromEmerson) return renderEmersonEcologicsLogo();

    if (currentTheme.theme === "dark") {
      return (
        <SvgFullscriptLogo
          css={styles.svgContainer}
          aria-label={t(l.aviary.sidebarNav.fullscriptLogo)}
        />
      );
    }

    return (
      <SvgFullscriptLogoDarkTextGreenLeaf
        css={styles.svgContainer}
        data-testid="fullscript-logo"
        aria-label={t(l.aviary.sidebarNav.fullscriptLogo)}
      />
    );
  };

  const renderLogo = () => {
    const content = (
      <div
        css={[
          styles.logoWrapper.base,
          (showFromEmerson || isEmerson) && styles.logoWrapper.fromCompanyByFullscript,
        ]}
      >
        {renderPlatformLogo()}
      </div>
    );

    if (route) {
      return (
        <Link aria-label="login" to={route}>
          {content}
        </Link>
      );
    }

    return content;
  };

  return <Header css={(showFromEmerson || isEmerson) && styles.header}>{renderLogo()}</Header>;
};

export { AuthHeader };
